const appConfig = {
    //apiPrefix: 'https://localhost:7226/api',
    apiPrefix: 'https://api.armedi.mx/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
